import { isMobile } from "react-device-detect";
export const mobile = isMobile;
export const tablet = isMobile && window.innerWidth > 767;
export const isPhone = mobile && !tablet;
export const ICON_SIZE = 24;
export const currentYear = new Date().getFullYear();
// export const BACKEND_URL = "http://localhost:8095";
export const BACKEND_URL = "https://backend.psiworks.pt";
export const timezoneOffset = new Date().getTimezoneOffset();
// export const localServerUrl = "http://localhost:3000";
// export const localServerUrl = "https://www.psiworks.pt";

export const localServerUrl = "https://psiworks.pt";
export const WIDGET_IFRAME_SRC = "https://widget.frontend.psilink.pt";
// export const WIDGET_IFRAME_SRC = "http://localhost:3001";

// export const WIDGET_PROVIDER_ID = 31;
export const WIDGET_PROVIDER_ID = 219;

export const MEET_URL = "meet.psicall.com";
export const GA_MEASUREMENT_ID = "G-KWQZ7RZCPQ";

export const WP_URL = "https://wp.psiworks.pt";

export const WP_CMS = WP_URL + "/graphql";

export const firebaseConfig = {
  apiKey: "AIzaSyDDxDX6H87VVUYN0UbOpmmyvtpBzioiTaA",
  authDomain: "psi-works-backoffice.firebaseapp.com",
  databaseURL:
    "https://psi-works-backoffice-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "psi-works-backoffice",
  storageBucket: "psi-works-backoffice.appspot.com",
  messagingSenderId: "17152335131",
  appId: "1:17152335131:web:169e8562902a4e734447be",
};
